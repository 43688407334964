import PropTypes from 'prop-types';

// component
import JourneyCard from '@/components/shared/timeTableSection/partials/journeyCard';

// material
import { Alert, Box } from '@mui/material';

// styles
import useStyles from './style';

export default function Error({ colorSchemes, message }) {
	const { classes } = useStyles();

	return (
		<Box className={classes.blurredSection}>
			<JourneyCard loading={true} blur={true} colorScheme={colorSchemes[0]} />
			<Box className={classes.errorSection}>
				<Alert severity="warning" className={classes.alert}>
					{message}
				</Alert>
			</Box>
		</Box>
	);
}

Error.propTypes = {
	colorSchemes: PropTypes.array.isRequired,
	message: PropTypes.string.isRequired,
};
