import PropTypes from 'prop-types';

// components
import Loader from './loader';
import Task from './task';

export default function SubTask({ loading, ...props }) {
	return loading ? <Loader /> : <Task {...props} />;
}

SubTask.propTypes = {
	loading: PropTypes.bool,
	name: PropTypes.string,
	date: PropTypes.string,
	blurDueDate: PropTypes.bool,
};

SubTask.defaultProps = {
	loading: false,
};
