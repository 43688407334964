import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// material
import { Box, CircularProgress, Skeleton, Typography } from '@mui/material';

// components
import SubTask from '@/components/shared/timeTableSection/partials/subTask';

// styles
import useStyles from './style';

export default function Loader(props) {
	const { classes, cx } = useStyles(props);
	const { isMobile, isTablet } = useSelector((state) => state.common);

	return (
		<Box className={cx(classes.task, props.blur ? classes.blur : '')}>
			<Box className={classes.taskLogoWrap}>
				<CircularProgress size="20px" />
			</Box>
			<Box className={classes.taskBody}>
				<Box className={classes.taskTitleWrap}>
					<Typography
						variant={isMobile ? 'subtitle2' : 'h5'}
						fontWeight="fontWeightSemiBold"
					>
						<Skeleton
							width={isMobile ? '200px' : isTablet ? '300px' : '400px'}
							height="100%"
						/>
					</Typography>
				</Box>

				<Typography className={classes.description}>
					<Skeleton width={isMobile ? '100%' : '90%'} height="100%" />
				</Typography>

				<Box>
					{Array.from({ length: 3 }, (_, index) => (
						<Box
							key={index}
							className={cx(index === 0 ? '' : classes.borderTop)}
						>
							<SubTask loading={true} />
						</Box>
					))}
				</Box>
			</Box>
		</Box>
	);
}

Loader.propTypes = {
	colorScheme: PropTypes.string.isRequired,
	blur: PropTypes.bool,
};
