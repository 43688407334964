import PropTypes from 'prop-types';

// component
import Loader from './loader';
import Title from './title';

export default function JourneyTitle({ loading, ...props }) {
	return loading ? <Loader blur={props.blur} /> : <Title {...props} />;
}

JourneyTitle.propTypes = {
	title: PropTypes.string,
	blurredDueDateText: PropTypes.string,
	icon: PropTypes.object,
	loading: PropTypes.bool,
	blur: PropTypes.bool,
};

JourneyTitle.defaultProps = {
	loading: false,
};
