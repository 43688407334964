import { makeStyles } from 'tss-react/mui';

const colorScheme = {
	orange: {
		backgroundColor: 'rgba(255, 245, 237, 0.87)',
		borderColor: '#FFD4AA',
		iconBackgroundColor: '#FFE6CC',
	},
	green: {
		backgroundColor: 'rgba(228, 255, 228, 1)',
		borderColor: '#B4EAB4',
		iconBackgroundColor: '#B4EAB4',
	},
	blue: {
		backgroundColor: '#E3F1FF',
		borderColor: '#C7E1FB',
		iconBackgroundColor: '#C7E1FB',
	},
};

const useStyles = makeStyles()((theme, props = {}) => ({
	task: {
		borderRadius: '17px',
		padding: '20px 28px 20px 20px',
		background: colorScheme[props.colorScheme].backgroundColor,
		display: 'flex',
		'& > * + *': {
			marginLeft: '16px',
			width: '100%',
			[theme.breakpoints.down('sm')]: {
				marginLeft: '8px',
			},
		},
		[theme.breakpoints.down('sm')]: {
			padding: '10px',
			borderRadius: '8px',
		},
	},
	taskLogoWrap: {
		position: 'relative',
		width: '50px',
		height: '50px',
		borderRadius: '50%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexShrink: 0,
		backgroundColor: colorScheme[props.colorScheme].iconBackgroundColor,
		[theme.breakpoints.down('sm')]: {
			width: '30px',
			height: '30px',
		},
	},
	taskLogo: {
		padding: '10px!important',
		[theme.breakpoints.down('sm')]: {
			padding: '5px!important',
		},
	},
	taskBody: {
		'& > * + *': {
			marginTop: '16px',
		},
	},
	taskTitleWrap: {
		display: 'flex',
		alignItems: 'center',
		'& > * + *': {
			height: '32px',
			borderRadius: '10px',
			padding: '4px 8px',
			marginLeft: '50px',
			whiteSpace: 'nowrap',
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.common.white,
			fontWeight: theme.typography.fontWeightMedium,
			[theme.breakpoints.down('sm')]: {
				marginLeft: '12px',
				borderRadius: '5px',
				height: 'auto',
			},
		},
	},
	borderTop: {
		borderTop: `2px solid ${colorScheme[props.colorScheme].borderColor}`,
	},
	blur: {
		filter: 'blur(7.5px)',
	},
	description: {
		fontWeight: theme.typography.fontWeightMedium,
		color: theme.palette.text.disabled,
		fontSize: '18px',
		[theme.breakpoints.down('sm')]: {
			fontSize: '12px',
		},
	},
}));

export default useStyles;
