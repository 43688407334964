import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// constants
import constants from '@/constants';

// component
import JourneyCard from '@/components/shared/timeTableSection/partials/journeyCard';

// material
import { Info } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';

// styles
import useStyles from './style';

// utils
import * as eventActions from '@/utils/analytics';
import useUserModal from '@/utils/hooks/use-login-modal';
const buildGetColorScheme = (colorSchemes) => {
	const colorSchemeCount = colorSchemes.length;

	return (index) =>
		colorSchemes[
			((index % colorSchemeCount) + colorSchemeCount) % colorSchemeCount
		];
};

export default function Journey({
	tasks,
	colorSchemes,
	buttonText,
	disclaimerText,
	analyticsProps,
}) {
	const { classes } = useStyles();
	const openLoginModal = useUserModal();
	const isMobile = useSelector((state) => state.common.isMobile);
	const user = useSelector((state) => state.user.data);

	const getColorSchemeByIndex = React.useCallback(
		buildGetColorScheme(colorSchemes),
		[colorSchemes]
	);

	const handleButtonClick = () => {
		eventActions.trackEvent('BUTTON_CLICKED', {
			...analyticsProps,
			event_action: 'CLICKED',
			eventCategory: user && user.id ? 'NAVIGATION' : 'USER_EVENT',
			subSection: 'BLURRED_SECTION',
			eventLabel: buttonText,
		});
		openLoginModal('login', '', {
			redirectionLink: `${constants.studentPlatformUrl}timetable`,
		});
	};

	const renderCTASection = () => {
		const ButtonElement = (
			<Button
				variant="contained"
				onClick={handleButtonClick}
				className={classes.ctaButton}
				fullWidth={isMobile}
			>
				{buttonText}
			</Button>
		);

		if (isMobile) {
			return (
				<Box className={classes.ctaSectionMobileWrap}>
					<Box className={classes.ctaSectionMobile}>
						{ButtonElement}

						<Box className={classes.disclaimerWrap}>
							<Info fontSize="inherit" sx={{ verticalAlign: 'sub' }} />
							<Typography
								component="span"
								fontSize="inherit"
								fontWeight="inherit"
							>
								{disclaimerText}
							</Typography>
						</Box>
					</Box>
				</Box>
			);
		}

		return (
			<Box className={classes.ctaSection}>
				{ButtonElement}

				<Box className={classes.disclaimerWrap}>
					<Info fontSize="inherit" sx={{ verticalAlign: 'sub' }} />
					<Typography component="span" fontSize="inherit" fontWeight="inherit">
						{disclaimerText}
					</Typography>
				</Box>
			</Box>
		);
	};

	return (
		<Box className={classes.taskList}>
			{tasks.slice(0, 2).map((task, index) => (
				<JourneyCard
					key={index}
					icon={task.icon}
					name={task.name}
					description={task.description}
					dueDate={task.date}
					subTasks={task.subTasks}
					subTasksConfig={{
						blurDueDate: index > 0,
						startIndex: isMobile ? 0 : 1,
					}}
					colorScheme={getColorSchemeByIndex(index)}
				/>
			))}
			<Box className={classes.blurredSection}>
				{renderCTASection()}
				{tasks.slice(2).map((task, index) => (
					<JourneyCard
						key={index}
						icon={task.icon}
						name={task.name}
						description={task.description}
						blur={true}
						colorScheme={getColorSchemeByIndex(index + 2)}
					/>
				))}
			</Box>
		</Box>
	);
}

Journey.propTypes = {
	tasks: PropTypes.array.isRequired,
	colorSchemes: PropTypes.array.isRequired,
	buttonText: PropTypes.string.isRequired,
	disclaimerText: PropTypes.string.isRequired,
	analyticsProps: PropTypes.object,
};

Journey.defaultProps = {
	analyticsProps: {},
};
