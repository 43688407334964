import PropTypes from 'prop-types';

// constants
export const colorSchemes = ['orange', 'green', 'blue', 'orange', 'green'];

// components
import Error from './error';
import Journey from './journey';
import Loader from './loader';

export default function JourneySection({
	loading,
	errorText,
	tasks,
	...props
}) {
	const errorMessage =
		errorText || ((!tasks || tasks.length === 0) && 'No data available');

	return loading ? (
		<Loader colorSchemes={colorSchemes} />
	) : errorMessage ? (
		<Error message={errorMessage} colorSchemes={colorSchemes} />
	) : (
		<Journey {...props} tasks={tasks} colorSchemes={colorSchemes} />
	);
}

JourneySection.propTypes = {
	loading: PropTypes.bool,
	errorText: PropTypes.string,
	tasks: PropTypes.array,
	buttonText: PropTypes.string,
	disclaimerText: PropTypes.string,
	analyticsProps: PropTypes.object
};

JourneySection.defaultProps = {
	loading: false,
};
