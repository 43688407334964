import PropTypes from 'prop-types';

// components
import JourneyCard from '@/components/shared/timeTableSection/partials/journeyCard';

// material
import { Box } from '@mui/material';

// styles
import useStyles from './style';

export default function Loader({ colorSchemes }) {
	const { classes } = useStyles();

	return (
		<Box className={classes.taskList}>
			{Array.from({ length: 5 }, (_, index) => (
				<JourneyCard
					key={index}
					loading={true}
					colorScheme={colorSchemes[index]}
				/>
			))}
		</Box>
	);
}

Loader.propTypes = {
	colorSchemes: PropTypes.array.isRequired,
};
