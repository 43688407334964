import PropTypes from 'prop-types';

// components
import Card from './card';
import Loader from './loader';

export default function JourneyCard({ loading, ...props }) {
	return loading ? (
		<Loader colorScheme={props.colorScheme} blur={props.blur} />
	) : (
		<Card {...props} />
	);
}

JourneyCard.propTypes = {
	loading: PropTypes.bool,
	blur: PropTypes.bool,
	subTasksConfig: PropTypes.object,
	icon: PropTypes.object,
	name: PropTypes.string,
	description: PropTypes.string,
	dueDate: PropTypes.string,
	subTasks: PropTypes.array,
	colorScheme: PropTypes.string,
};

JourneyCard.defaultProps = {
	loading: false,
};
