import { makeStyles } from 'tss-react/mui';

const commonButtonStyles = {
	borderRadius: '25px',
	display: 'inline-block',
	padding: '10px 27px',
	border: '1px solid #C4C4C4',
	fontSize: '16px',
	boxShadow: 'none',
};

const useStyles = makeStyles()((theme) => ({
	filterSection: {
		display: 'flex',
		justifyContent: 'space-between',
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
			'&> * + *': {
				marginTop: '32px',
			},
		},
	},
	styled: {
		boxShadow: '0px 0px 60px rgb(254 200 152 / 87%)',
		padding: '40px',
		borderRadius: '20px',
		[theme.breakpoints.down('xl')]: {
			padding: '30px',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '20px',
		},
	},
	titleSection: {
		width: '40%',
		borderRight: '2px solid #F16621',
		[theme.breakpoints.down('md')]: {
			width: '100%',
			borderRight: 'none',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			'&> * + *': {
				marginLeft: '24px',
			},
		},
	},
	filterInnerSection: {
		width: '55%',
		'&> * + *': {
			marginTop: '60px',
			[theme.breakpoints.down('md')]: {
				marginTop: '35px',
			},
		},
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
	},
	questionWrap: {
		'& > * + *': {
			marginTop: '24px',
			[theme.breakpoints.down('sm')]: {
				marginTop: '16px',
			},
		},
	},
	questionLabel: {
		...theme.typography.h5,
		fontWeight: theme.typography.fontWeightMedium,
		[theme.breakpoints.down('sm')]: {
			fontSize: '18px',
		},
	},
	countryOptionWrap: {
		display: 'flex',
		flexWrap: 'wrap',
		margin: '-12px 0 0 -16px',

		'& > *': {
			width: 'calc(25% - 16px)',
			margin: '28px 0 0 16px',
			'& p': {
				fontSize: '10px!important',
				fontWeight: theme.typography.fontWeightMedium,
			},
		},
	},
	countryOption: {
		cursor: 'pointer',
		paddingTop: '4px',
		'& p': {
			fontWeight: theme.typography.fontWeightMedium,
		},
		[theme.breakpoints.down('sm')]: {
			paddingTop: '0',
		},
	},
	intakeOptionWrap: {
		margin: '5px 0 0 -19px!important',
		'& > *': { margin: '19px 0 0 19px' },

		[theme.breakpoints.down('sm')]: {
			display: 'flex',
			flexWrap: 'wrap',
			justifyContent: 'center',
			margin: '3px 0 0 -13px',
			'& > *': { margin: '13px 0 0 13px', flex: '0 0 calc(50% - 13px)' },
		},
	},
	intake: {
		...commonButtonStyles,
		fontWeight: theme.typography.fontWeightMedium,
	},
	answerButtonWrap: {
		display: 'flex',
	},
	answerButton: {
		...commonButtonStyles,
		fontWeight: theme.typography.fontWeightMedium,
		width: '50%',
		'&:last-child': {
			marginLeft: '20px',
		},
	},

	prevArrow: {
		position: 'absolute',
		top: 'calc(50% - 12px)',
		left: -20,
		transform: 'translateY(-50%)',
		zIndex: 100,
		cursor: 'pointer',
		width: '25px',
		height: '25px',
		borderRadius: '50%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: 'white',
		background: theme.palette.primary.main,
	},
	nextArrow: {
		position: 'absolute',
		top: 'calc(50% - 12px)',
		right: -20,
		transform: 'translateY(-50%)',
		cursor: 'pointer',
		zIndex: 100,
		width: '25px',
		height: '25px',
		borderRadius: '50%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: 'white',
		background: theme.palette.primary.main,
	},

	cursorPointer: {
		cursor: 'pointer',
	},
	selected: {
		color: theme.palette.primary.main,
		'& > div > div:first-of-type': {
			boxShadow: `0 0 5px ${theme.palette.primary.main}`,
		},
	},

	filterPillWrap: {
		marginBottom: '30px',
		color: '#A9A9A9',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'flex-end',
	},
	filterPill: {
		display: 'inline-block',
		borderRadius: '27px',
		padding: '10px 24px',
		marginRight: '16px',
		fontWeight: theme.typography.fontWeightSemiBold,
		color: theme.palette.primary.main,
		outline: `1.5px solid ${theme.palette.primary.main}`,
	},
}));

export default useStyles;
