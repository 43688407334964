import { useSelector } from 'react-redux';

// material
import { AccessTime } from '@mui/icons-material';
import { Box, Skeleton, Typography } from '@mui/material';

// styles
import useStyles from './style';

export default function Loader() {
	const { classes } = useStyles();

	const { isMobile, isTablet } = useSelector((state) => state.common);

	return (
		<Box className={classes.subTask}>
			<Typography component="span" className={classes.title}>
				<Skeleton
					width={isMobile ? '100px' : isTablet ? '200px' : '400px'}
					height="100%"
				/>
			</Typography>
			<Box className={classes.dueDateWrap}>
				<AccessTime
					sx={{ color: 'primary.main', fontSize: isMobile ? '16px' : '20px' }}
				/>
				<Typography component="span" className={classes.dueDate}>
					<Skeleton width="100%" height="100%" />
				</Typography>
			</Box>
		</Box>
	);
}
