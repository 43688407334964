export const calenderIcon = {
	url: 'https://production-azent-website-cms-uploads.s3.ap-south-1.amazonaws.com/calendar_9242509f4b.png',
	alternativeText: 'time-table-calender',
};

export const countriesIcons = {
	uk: {
		name: 'UK',
		alternativeText: 'UK',
		url: 'https://production-azent-website-cms-uploads.s3.ap-south-1.amazonaws.com/uk_Flag_Icon_5458d8dc24.svg',
	},
	usa: {
		name: 'USA',
		alternativeText: 'USA',
		url: 'https://production-azent-website-cms-uploads.s3.ap-south-1.amazonaws.com/usa_Flag_Icon_dea2fc1c35.svg',
	},
	australia: {
		name: 'Australia',
		alternativeText: 'Australia',
		url: 'https://production-azent-website-cms-uploads.s3.ap-south-1.amazonaws.com/australia_Flag_Icon_04830852b9.svg',
	},
	canada: {
		name: 'Canada',
		alternativeText: 'Canada',
		url: 'https://production-azent-website-cms-uploads.s3.ap-south-1.amazonaws.com/canada_Flag_Icon_1f05eba126.svg',
	},
	singapore: {
		name: 'Singapore',
		alternativeText: 'Singapore',
		url: 'https://production-azent-website-cms-uploads.s3.ap-south-1.amazonaws.com/singapore_Flag_Icon_4745081282.svg',
	},
	ireland: {
		name: 'Ireland',
		alternativeText: 'Ireland',
		url: 'https://production-azent-website-cms-uploads.s3.ap-south-1.amazonaws.com/ireland_Flag_Icon_ca9c227fd1.svg',
	},
};

export const otherCountriesIcons = {
	germany: {
		name: 'Germany',
		alternativeText: 'Germany',
		url: 'https://production-azent-website-cms-uploads.s3.ap-south-1.amazonaws.com/germany_Flag_Icon_cc77c0f575.svg',
	},
	'new zealand': {
		name: 'New Zealand',
		alternativeText: 'New Zealand',
		url: 'https://production-azent-website-cms-uploads.s3.ap-south-1.amazonaws.com/new_Zealand_Flag_Icon_8085d0b756.svg',
	},
	dubai: {
		name: 'Dubai',
		alternativeText: 'Dubai',
		url: 'https://production-azent-website-cms-uploads.s3.ap-south-1.amazonaws.com/dubai_Flag_Icon_52c23e4cd1.svg',
	},
	france: {
		name: 'France',
		alternativeText: 'France',
		url: 'https://production-azent-website-cms-uploads.s3.ap-south-1.amazonaws.com/france_Icon_Flag_62111ff092.svg',
	},
	italy: {
		name: 'Italy',
		alternativeText: 'Italy',
		url: 'https://production-azent-website-cms-uploads.s3.ap-south-1.amazonaws.com/italy_Flag_Icon_bfb3bd3f2b.svg',
	},
	malaysia: {
		name: 'Malaysia',
		alternativeText: 'Malaysia',
		url: 'https://production-azent-website-cms-uploads.s3.ap-south-1.amazonaws.com/malaysia_Flag_Icon_8cb17c4546.svg',
	},
	spain: {
		name: 'Spain',
		alternativeText: 'Spain',
		url: 'https://production-azent-website-cms-uploads.s3.ap-south-1.amazonaws.com/spain_Flag_Icon_a5a6b6d0f8.svg',
	},
};

export const textValues = {
	examTakenValue: 'Yes',
	examNotTakenValue: 'No',
	examTakenQuestionId: 'Exam_Taken_Question',
	intakeQuestionId: 'Target_Intake_Question',
};
