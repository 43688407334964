import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// component
import ImageComponent from '@/components/widgets/image';

// material
import { Box, Typography } from '@mui/material';

// styles
import useStyles from './style';

export default function Title({ title, icon, blurredDueDateText, blur }) {
	const { classes, cx } = useStyles();
	const isMobile = useSelector((state) => state.common.isMobile);

	return (
		<Box className={cx(classes.titleWrap, blur ? classes.blur : '')}>
			{icon && icon.url && (
				<ImageComponent
					width={isMobile ? '28px' : '55px'}
					height={isMobile ? '23px' : '46px'}
					src={icon.url}
					alt={icon.alternativeText}
					layout="fixed"
				/>
			)}
			<Typography>
				<Typography
					component="span"
					fontWeight="fontWeightSemiBold"
					variant={isMobile ? 'h6' : 'h5'}
				>{`${title} `}</Typography>
				{blurredDueDateText && (
					<Typography
						component="span"
						color="primary"
						fontWeight="fontWeightSemiBold"
						variant={isMobile ? 'h6' : 'h5'}
						className={classes.bannerDueDate}
					>
						{blurredDueDateText}
					</Typography>
				)}
			</Typography>
		</Box>
	);
}

Title.propTypes = {
	icon: PropTypes.object,
	title: PropTypes.string.isRequired,
	blur: PropTypes.bool,
	blurredDueDateText: PropTypes.string,
};

Title.defaultProps = {
	blur: false,
};
