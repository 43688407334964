import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	journeySection: {
		backgroundColor: theme.palette.common.white,
		[theme.breakpoints.down('sm')]: {
			backgroundColor: 'unset',
		},
	},
	taskList: {
		'& > * + *': {
			marginTop: '36px',
			[theme.breakpoints.down('sm')]: {
				marginTop: '16px',
			},
		},

		[theme.breakpoints.down('sm')]: {
			backgroundColor: theme.palette.common.white,
			borderRadius: '14px',
			padding: '14px',
			boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)',
		},
	},
	blurredSection: {
		position: 'relative',

		'& > * + *': {
			marginTop: '36px',
			[theme.breakpoints.down('sm')]: {
				marginTop: '16px',
			},
		},
	},
	ctaSection: {
		margin: 0,
		height: '100%',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'absolute',
		top: '50%',
		left: '50%',
		zIndex: 1,
		transform: 'translate(-50%, -50%)',
		'& > * + *': {
			marginTop: '16px',
		},
	},
	ctaSectionMobileWrap: {
		position: 'absolute',
		height: '100%',
		width: '100%',
	},
	ctaSectionMobile: {
		position: 'sticky',
		top: '80px',
		zIndex: 1,
		textAlign: 'center',
		'& > * + *': {
			marginTop: '16px',
		},
	},
	ctaButton: {
		borderRadius: '44.351px',
		padding: '16px 36px',
		fontWeight: 600,
		fontSize: '24px',
		lineHeight: 1.21,
		background:
			'linear-gradient(228deg, rgba(245, 131, 32, 0.87) 0%, rgba(241, 105, 33, 0.99) 63.27%, #D75312 84.29%)',
		[theme.breakpoints.down('sm')]: {
			fontSize: '16px',
		},
	},
	disclaimerWrap: {
		fontWeight: theme.typography.fontWeightMedium,
		fontSize: '18px',
		color: '#6D6D6D',
		'& > * + *': {
			marginLeft: '4px',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '14px',
		},
	},
	blur: {
		filter: 'blur(7.5px)',
	},
	errorSection: {
		margin: 0,
		height: '100%',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
	},
	alert: {
		width: '100%',
		justifyContent: 'center',
		alignItems: 'center',
		...theme.typography.h6,
		fontWeight: `${theme.typography.fontWeightBold}!important`,
		[theme.breakpoints.down('sm')]: {
			...theme.typography.body1,
		},
	},
}));

export default useStyles;
