import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// material
import { Box, CircularProgress, Skeleton, Typography } from '@mui/material';

// styles
import useStyles from './style';

export default function Loader({ blur }) {
	const { classes, cx } = useStyles();
	const { isMobile, isTablet } = useSelector((state) => state.common);

	return (
		<Box className={cx(classes.titleWrap, blur ? classes.blur : '')}>
			<CircularProgress size="40px" />

			<Typography
				component="span"
				fontWeight="fontWeightSemiBold"
				variant={isMobile ? 'body1' : 'h5'}
			>
				<Skeleton
					width={isMobile ? '200px' : isTablet ? '300px' : '400px'}
					height="100%"
				/>
			</Typography>
		</Box>
	);
}

Loader.propTypes = {
	blur: PropTypes.bool,
};

Loader.defaultProps = {
	blur: false,
};
