import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	journeySectionWrap: {
		border: `2px solid ${theme.palette.primary.main}`,
		borderRadius: '26px',
		padding: '22px 24px 30px',
		[theme.breakpoints.down('sm')]: {
			border: 'none',
			borderRadius: 'none',
			padding: 0,
		},
	},
	titleWrap: {
		marginBottom: '30px',
		[theme.breakpoints.down('sm')]: {
			marginBottom: '30px',
		},
	},
	appliedFiltersWrap: {
		marginBottom: '30px',
		[theme.breakpoints.down('sm')]: {
			marginBottom: '8px',
		},
	},
	appliedFiltersRow: {
		color: '#A9A9A9',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'flex-end',
	},
	filterPill: {
		display: 'inline-block',
		padding: '6.5px 22.5px',
		marginRight: '16px',
		fontWeight: theme.typography.fontWeightSemiBold,
		color: theme.palette.primary.main,
		borderRadius: '27px',
		border: `1.5px solid ${theme.palette.primary.main}`,
		[theme.breakpoints.down('sm')]: {
			fontSize: '12px',
			padding: '4.5px 14.5px',
		},
	},
}));

export default useStyles;
