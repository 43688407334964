import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { scroller } from 'react-scroll';

// constants
import { calenderIcon, textValues } from './constants';

// components
import ComponentSlider from '@/components/shared/componentSlider';
import ImageCard from '@/components/shared/imageCard';
import ImageComponent from '@/components/widgets/image';

// material
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';

// utils
import { getTargetCountries, getTargetIntakes } from './util';

// styles
import useStyles from './style';

export default function FilterSection({
	selectedCountry,
	selectedIntake,
	examTaken,
	handleExamTakenClick,
	...props
}) {
	const { classes, cx } = useStyles();

	const { isMobile, isDesktop } = useSelector((state) => state.common);
	const targetIntakes = useSelector((state) =>
		getTargetIntakes(
			get(
				state,
				'cmsGlobalData.data.globalFormConfig.schemaConstants.target_intake.enum',
				[]
			)
		)
	);
	const targetCountries = useSelector((state) =>
		getTargetCountries(get(state, 'global.data.countries', []))
	);

	const handleCountryClick = (value) => {
		props.handleCountryClick(value);
		if (isMobile && value !== selectedCountry)
			scroller.scrollTo(textValues.intakeQuestionId, { offset: -90 });
	};

	const handleIntakeClick = (value) => {
		props.handleIntakeClick(value);
		if (isMobile && value !== selectedIntake)
			scroller.scrollTo(textValues.examTakenQuestionId, { offset: -90 });
	};

	return (
		<Box
			className={cx(classes.filterSection, props.styled ? classes.styled : '')}
		>
			<Box className={classes.titleSection}>
				<ImageComponent
					src={calenderIcon.url}
					alt={calenderIcon.alternativeText}
					width={isMobile ? '52px' : '130px'}
					height={isMobile ? '54px' : '126px'}
				/>
				<Typography component="h2" mt={isDesktop ? 4 : 0}>
					{props.highlightTitle && (
						<Typography
							variant={isMobile ? 'h6' : isDesktop ? 'h3' : 'h4'}
							component="span"
							color="primary"
							display="block"
							fontWeight="fontWeightSemiBold"
						>{`${props.highlightTitle} `}</Typography>
					)}
					<Typography
						variant={isMobile ? 'h6' : isDesktop ? 'h3' : 'h4'}
						component="span"
						fontWeight="fontWeightSemiBold"
					>
						{props.title}
					</Typography>
				</Typography>
			</Box>
			<Box className={classes.filterInnerSection}>
				<Box className={classes.questionWrap}>
					<Typography className={classes.questionLabel}>
						{props.targetCountryLabel}
					</Typography>
					{isMobile ? (
						<Box className={classes.countryOptionWrap}>
							{targetCountries.map((icon, index) => (
								<Box
									key={index}
									className={cx(
										classes.countryOption,
										selectedCountry === icon.name ? classes.selected : ''
									)}
									onClick={() => handleCountryClick(icon.name)}
								>
									<ImageCard
										image={icon}
										label={icon.alternativeText}
										imageWidth="55px"
										imageShape="circle"
									/>
								</Box>
							))}
						</Box>
					) : (
						<ComponentSlider
							focusOnSelect={false}
							slidesToShow={isDesktop ? 6 : 3}
							arrows={!isMobile}
							useTransform={false}
							cssEase="linear"
							accessibility={false}
							className={classes.bannerSection}
							analyticsPropsSubSections={targetCountries.map(
								(item) => item.alternativeText
							)}
							nextArrow={({ onClick, trackEvent }) => (
								<Box
									className={classes.nextArrow}
									onClick={(clickArgs) => {
										trackEvent('RIGHT');
										onClick(clickArgs);
									}}
								>
									<ChevronRight fontSize="medium" />
								</Box>
							)}
							prevArrow={({ onClick, trackEvent }) => (
								<Box
									className={classes.prevArrow}
									onClick={(clickArgs) => {
										trackEvent('LEFT');
										onClick(clickArgs);
									}}
								>
									<ChevronLeft fontSize="medium" />
								</Box>
							)}
							responsive={[
								{
									breakpoint: 1025,
									settings: { slidesToShow: 3 },
								},
							]}
						>
							{targetCountries.map((icon, index) => (
								<Box
									key={index}
									className={cx(
										classes.countryOption,
										selectedCountry === icon.name ? classes.selected : ''
									)}
									onClick={() => handleCountryClick(icon.name)}
								>
									<ImageCard
										image={icon}
										label={icon.alternativeText}
										imageWidth="55px"
										imageShape="circle"
									/>
								</Box>
							))}
						</ComponentSlider>
					)}
				</Box>
				<Box className={classes.questionWrap} id={textValues.intakeQuestionId}>
					<Typography
						color={selectedCountry ? 'inherit' : 'text.lightGrey'}
						className={classes.questionLabel}
					>
						{props.targetIntakeLabel}
					</Typography>
					<Box className={classes.intakeOptionWrap}>
						{targetIntakes.map((intake, index) => (
							<Button
								key={index}
								color={selectedIntake === intake ? 'primary' : 'inherit'}
								variant={selectedIntake === intake ? 'contained' : 'outlined'}
								onClick={() => handleIntakeClick(intake)}
								className={classes.intake}
								disabled={!selectedCountry}
							>
								{intake}
							</Button>
						))}
					</Box>
				</Box>
				<Box
					className={classes.questionWrap}
					id={textValues.examTakenQuestionId}
				>
					<Typography
						color={
							!selectedCountry || !selectedIntake ? 'text.lightGrey' : 'inherit'
						}
						className={classes.questionLabel}
					>
						{props.testTakenLabel}
					</Typography>
					<Box className={classes.answerButtonWrap}>
						<Button
							className={classes.answerButton}
							color={
								examTaken === textValues.examTakenValue ? 'primary' : 'inherit'
							}
							variant={
								examTaken === textValues.examTakenValue
									? 'contained'
									: 'outlined'
							}
							onClick={() => handleExamTakenClick(textValues.examTakenValue)}
							disabled={!selectedCountry || !selectedIntake}
						>
							{textValues.examTakenValue}
						</Button>
						<Button
							className={classes.answerButton}
							color={
								examTaken === textValues.examNotTakenValue
									? 'primary'
									: 'inherit'
							}
							variant={
								examTaken === textValues.examNotTakenValue
									? 'contained'
									: 'outlined'
							}
							onClick={() => handleExamTakenClick(textValues.examNotTakenValue)}
							disabled={!selectedCountry || !selectedIntake}
						>
							{textValues.examNotTakenValue}
						</Button>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}

FilterSection.propTypes = {
	title: PropTypes.string.isRequired,
	highlightTitle: PropTypes.string,
	selectedCountry: PropTypes.string,
	selectedIntake: PropTypes.string,
	examTaken: PropTypes.string,
	targetCountryLabel: PropTypes.string.isRequired,
	targetIntakeLabel: PropTypes.string.isRequired,
	testTakenLabel: PropTypes.string.isRequired,
	handleCountryClick: PropTypes.func.isRequired,
	handleIntakeClick: PropTypes.func.isRequired,
	handleExamTakenClick: PropTypes.func.isRequired,
	styled: PropTypes.bool,
};

FilterSection.defaultProps = {
	styled: false,
};
