import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// components
import SubTask from '@/components/shared/timeTableSection/partials/subTask';
import ImageComponent from '@/components/widgets/image';

// materials
import { Box, Typography } from '@mui/material';

// styles
import useStyles from './style';

const blurredDueDateText = 'dd mmm yyyy';
export default function Card({
	icon,
	dueDate,
	subTasks,
	subTasksConfig,
	...props
}) {
	const { classes, cx } = useStyles(props);
	const isMobile = useSelector((state) => state.common.isMobile);

	return (
		<Box className={cx(classes.task, props.blur ? classes.blur : '')}>
			<Box className={classes.taskLogoWrap}>
				<ImageComponent
					src={icon.url}
					alt={icon.alternativeText}
					layout="fill"
					objectFit="contain"
					className={classes.taskLogo}
				/>
			</Box>
			<Box className={classes.taskBody}>
				<Box className={classes.taskTitleWrap}>
					<Typography
						variant={isMobile ? 'subtitle2' : 'h5'}
						fontWeight="fontWeightSemiBold"
					>
						{props.name}
					</Typography>
					{dueDate && (
						<Typography variant={isMobile ? 'caption' : 'body1'}>
							{dueDate}
						</Typography>
					)}
				</Box>

				<Typography className={classes.description}>
					{props.description}
				</Typography>

				{subTasks && subTasks.length > 0 && (
					<Box>
						{subTasks.map((task, index) => {
							const blurDueDate =
								subTasksConfig &&
								subTasksConfig.startIndex <= index &&
								subTasksConfig.blurDueDate;

							return (
								<Box
									key={index}
									className={cx(index === 0 ? '' : classes.borderTop)}
								>
									<SubTask
										name={task.name}
										blurDueDate={blurDueDate}
										date={
											blurDueDate && task.date
												? blurredDueDateText
												: task.date || ''
										}
									/>
								</Box>
							);
						})}
					</Box>
				)}
			</Box>
		</Box>
	);
}

Card.propTypes = {
	blur: PropTypes.bool,
	subTasksConfig: PropTypes.object,
	icon: PropTypes.object.isRequired,
	name: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	dueDate: PropTypes.string,
	subTasks: PropTypes.array,
	colorScheme: PropTypes.string.isRequired,
};

Card.defaultProps = {
	blur: false,
};
