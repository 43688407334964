import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// material
import { Edit } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';

// styles
import useStyles from './style';

export default function AppliedFiltersRow({
	disableIcon,
	selectedCountry,
	selectedIntake,
	examTaken,
	resetFilters,
}) {
	const { classes } = useStyles();
	const isMobile = useSelector((state) => state.common.isMobile);

	return (
		<Box className={classes.appliedFiltersRow}>
			<Box>
				<Typography className={classes.filterPill} component="span">
					{selectedCountry}
				</Typography>
				<Typography className={classes.filterPill} component="span">
					{selectedIntake}
				</Typography>
				<Typography className={classes.filterPill} component="span">
					{examTaken}
				</Typography>
			</Box>

			<IconButton
				disabled={disableIcon}
				onClick={resetFilters}
				sx={{ color: '#A9A9A9' }}
			>
				<Edit fontSize={isMobile ? 'small' : 'large'} />
			</IconButton>
		</Box>
	);
}

AppliedFiltersRow.propTypes = {
	disableIcon: PropTypes.bool,
	selectedCountry: PropTypes.string.isRequired,
	selectedIntake: PropTypes.string.isRequired,
	examTaken: PropTypes.string.isRequired,
	resetFilters: PropTypes.func.isRequired,
};

AppliedFiltersRow.defaultProps = {
	disableIcon: false,
};
