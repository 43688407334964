import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	subTask: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '12px 0',
	},
	title: {
		fontWeight: theme.typography.fontWeightMedium,
		fontSize: '18px',
		[theme.breakpoints.down('sm')]: {
			fontSize: '12px',
		},
	},
	dueDateWrap: {
		display: 'flex',
		alignItems: 'center',
		'& > * + *': {
			marginLeft: '8px',
			minWidth: '120px',
			[theme.breakpoints.down('sm')]: {
				marginLeft: '4px',
				minWidth: '75px',
			},
		},
	},
	dueDate: {
		whiteSpace: 'nowrap',
		fontStyle: 'italic',
		fontWeight: theme.typography.fontWeightMedium,
		color: theme.palette.primary.main,
		[theme.breakpoints.down('sm')]: {
			fontSize: '10px',
		},
	},
	blur: {
		filter: 'blur(7.5px)',
	},
}));

export default useStyles;
