import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// materials
import { AccessTime } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

// styles
import useStyles from './style';

export default function Task({ name, blurDueDate, date }) {
	const { classes, cx } = useStyles();
	const isMobile = useSelector((state) => state.common.isMobile);

	return (
		<Box className={classes.subTask}>
			<Typography component="span" className={classes.title}>
				{name}
			</Typography>
			{date && (
				<Box className={classes.dueDateWrap}>
					<AccessTime
						sx={{ color: 'primary.main', fontSize: isMobile ? '16px' : '20px' }}
					/>
					<Typography
						component="span"
						className={cx(classes.dueDate, blurDueDate ? classes.blur : '')}
					>
						{date}
					</Typography>
				</Box>
			)}
		</Box>
	);
}

Task.propTypes = {
	name: PropTypes.string.isRequired,
	date: PropTypes.string,
	blurDueDate: PropTypes.bool,
};

Task.defaultProps = {
	blurDueDate: false,
};
