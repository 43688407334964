import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { scroller } from 'react-scroll';

// constants
import { titleSizes } from '@/utils/constants/titleSizes';
import { textValues } from './constants';

// components
import TitleSection from '@/components/shared/titleSection';
import AppliedFiltersRow from './appliedFiltersRow';
import FilterSection from './partials/filterSection';
import Journey from './partials/journey';
import JourneyTitle from './partials/journeyTitle';

// material
import { Box } from '@mui/material';

// actions
import { getJourneyData } from '@/api';

//utils
import * as eventActions from '@/utils/analytics';
import { shapeJourneyData } from './util';

// styles
import useStyles from './style';

export default function TimeTableSection(props) {
	const { classes } = useStyles();
	const isMobile = useSelector((state) => state.common.isMobile);
	const isDesktop = useSelector((state) => state.common.isDesktop);

	const analyticsProps = {
		...props.analyticsProps,
		section: 'TIME_TABLE_SECTION',
	};

	// data
	const [data, setData] = React.useState(null);
	const [errorText, setErrorText] = React.useState('');

	// functionality
	const [loading, setLoading] = React.useState(true);
	const [journeyVisible, setJourneyVisible] = React.useState(false);

	// filters
	const [selectedCountry, setSelectedCountry] = React.useState('');
	const [selectedIntake, setSelectedIntake] = React.useState('');
	const [examTaken, setExamTaken] = React.useState('');

	const trackEvent = (eventName, eventProperties) => {
		eventActions.trackEvent(eventName, {
			...analyticsProps,
			...eventProperties,
		});
	};

	const handleCountryClick = (value) => {
		trackEvent('QUESTION_OPTION_CLICKED', {
			event_action: 'CLICKED',
			eventCategory: 'USER_EVENT',
			eventLabel: value,
			subSection: 'country',
		});
		setSelectedCountry(value === selectedCountry ? '' : value);
	};

	const handleIntakeClick = (value) => {
		trackEvent('QUESTION_OPTION_CLICKED', {
			event_action: 'CLICKED',
			eventCategory: 'USER_EVENT',
			eventLabel: value,
			subSection: 'intake',
			...analyticsProps,
		});
		setSelectedIntake(value === selectedIntake ? '' : value);
	};

	const handleExamTakenClick = (value) => {
		trackEvent('QUESTION_OPTION_CLICKED', {
			event_action: 'CLICKED',
			eventCategory: 'USER_EVENT',
			eventLabel: value,
			subSection: 'examTaken',
			...analyticsProps,
		});
		setExamTaken(value === examTaken ? '' : value);
	};

	const resetFilters = () => {
		trackEvent('BUTTON_CLICKED', {
			eventLabel: 'Reset filter icon',
			eventAction: 'CLICKED',
			eventCategory: 'USER_EVENT',
		});

		setSelectedCountry('');
		setSelectedIntake('');
		setExamTaken('');
		setErrorText('');
		setData(null);
		setJourneyVisible(false);
	};

	React.useEffect(() => {
		if (!(selectedCountry && selectedIntake && examTaken)) return;

		const [intake_month, intake_year] = selectedIntake.split(' ');
		const queryConfig = {
			target_country: selectedCountry,
			intake_month,
			intake_year,
			is_tests_opted: examTaken,
		};

		trackEvent('BUTTON_CLICKED', {
			eventLabel: 'Apply Filters',
			eventAction: 'FETCH_DATA',
			eventCategory: 'USER_EVENT',
			filterValues: queryConfig,
		});

		scroller.scrollTo(textValues.scrollId, { duration: 0, offset: -85 });
		setLoading(true);
		setJourneyVisible(true);
		getJourneyData(queryConfig)
			.then((response) => {
				const _data = shapeJourneyData(response.data);
				if (_data.type === textValues.intakeSuccessKey) setData(_data);
				else setErrorText(get(_data, 'banner.title', ''));
			})
			.catch((error) =>
				setErrorText(
					get(error, 'response.data.error.message', textValues.defaultErrorText)
				)
			)
			.finally(() => setLoading(false));
	}, [selectedCountry, selectedIntake, examTaken]);

	const AppliedFiltersRowElement = React.useMemo(() => {
		if (!(selectedCountry && selectedIntake && examTaken)) return null;

		return (
			<Box className={classes.appliedFiltersWrap}>
				<AppliedFiltersRow
					selectedCountry={selectedCountry}
					selectedIntake={selectedIntake}
					examTaken={examTaken}
					resetFilters={resetFilters}
				/>
			</Box>
		);
	}, [selectedCountry, selectedIntake, examTaken]);

	return (
		<>
			{isDesktop && (props.titleHeading || props.highlightTitleHeading) ? (
				<Box className={classes.titleWrap}>
					<TitleSection
						title={props.titleHeading}
						highlightTitle={props.highlightTitleHeading}
						fontSize={props.titleHeadingFontSize || titleSizes.medium}
						highlightTitleAfter={true}
					/>
				</Box>
			) : null}
			<Box id={textValues.scrollId}>
				{journeyVisible ? (
					<>
						{!isMobile && AppliedFiltersRowElement}
						<Box className={classes.journeySectionWrap}>
							<Box className={classes.titleWrap}>
								<JourneyTitle
									loading={!!(loading || errorText)}
									blur={!!errorText}
									title={get(data, 'banner.title', '')}
									blurredDueDateText={get(data, 'banner.dueDate', '')}
									icon={get(data, 'banner.icon')}
								/>
							</Box>

							{isMobile && AppliedFiltersRowElement}

							<Journey
								loading={loading}
								errorText={errorText}
								tasks={get(data, 'tasks')}
								buttonText={props.buttonText}
								disclaimerText={props.disclaimerText}
								analyticsProps={analyticsProps}
							/>
						</Box>
					</>
				) : (
					<FilterSection
						title={props.title}
						highlightTitle={props.highlightTitle}
						targetCountryLabel={props.targetCountryLabel}
						targetIntakeLabel={props.targetIntakeLabel}
						testTakenLabel={props.testTakenLabel}
						selectedCountry={selectedCountry}
						selectedIntake={selectedIntake}
						examTaken={examTaken}
						styled={props.styled}
						handleCountryClick={handleCountryClick}
						handleIntakeClick={handleIntakeClick}
						handleExamTakenClick={handleExamTakenClick}
					/>
				)}
			</Box>
		</>
	);
}

TimeTableSection.propTypes = {
	title: PropTypes.string,
	highlightTitle: PropTypes.string,
	titleHeading: PropTypes.string,
	highlightTitleHeading: PropTypes.string,
	titleHeadingFontSize: PropTypes.string,
	targetCountryLabel: PropTypes.string,
	targetIntakeLabel: PropTypes.string,
	testTakenLabel: PropTypes.string,
	buttonText: PropTypes.string,
	disclaimerText: PropTypes.string,
	styled: PropTypes.bool,
	analyticsProps: PropTypes.object,
};

TimeTableSection.defaultProps = {
	analyticsProps: {},
};
