export const textValues = {
	scrollId: 'TimeTableSection',
	blurredDueDateText: 'dd mm yyyy',
	intakeSuccessKey: 'success',
	defaultErrorText: 'Something went wrong, please try again later',
};

export const taskKeys = [
	'task_001',
	'task_002',
	'task_003',
	'task_004',
	'task_005',
	'task_006',
	'task_007',
];
