import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	titleWrap: {
		display: 'flex',
		justifyContent: 'center',
		'& > * + *': {
			marginLeft: '14px',
			marginTop: '6px',
			[theme.breakpoints.down('sm')]: {
				marginTop: 0,
				marginLeft: '7px',
			},
		},
		[theme.breakpoints.down('sm')]: {
			marginLeft: '-28px',
		},
	},
	bannerDueDate: {
		filter: 'blur(7.5px)',
		[theme.breakpoints.down('sm')]: {
			display: 'block',
			textAlign: 'center',
			marginTop: '4px',
		},
	},
	blur: {
		filter: 'blur(7.5px)',
	},
}));

export default useStyles;
