import get from 'lodash/get';
import moment from 'moment';
import { taskKeys, textValues } from './constants';

const getTask = (data) => ({
	name: data.task_name,
	description: data.task_description,
	date: get(data, 'sub_tasks[0].due_date')
		? moment(data.sub_tasks[0].due_date).format('Do MMM YYYY')
		: '',
	subTasks: data.sub_tasks.map((task) => ({
		name: task.subtask_name,
		key: task.subtask_key,
		description: task.subtask_description,
		date: task.due_date ? moment(task.due_date).format('Do MMM YYYY') : '',
	})),
	icon: { url: data.task_current_icon, alternativeText: data.task_name },
	loadingIcon: { url: data.task_locked_icon, alternativeText: data.task_name },
});

const getBanner = (firstTask, dueDate) => ({
	title: firstTask.banner_title,
	icon: firstTask.banner_icon
		? { url: firstTask.banner_icon, alternativeText: 'time_table_banner' }
		: null,
	dueDate: dueDate ? textValues.blurredDueDateText : '',
});

export const shapeJourneyData = (data) => {
	const _data = {};

	_data.tasks = taskKeys.reduce((tasks, key) => {
		if (data[key] && !data[key].is_last) tasks.push(getTask(data[key]));
		return tasks;
	}, []);

	if (data['task_001']) {
		_data.banner = getBanner(
			data['task_001'],
			_data.tasks[_data.tasks.length - 1].subTasks.slice(-1)[0].date
		);

		_data.type = data['task_001'].type;
	}

	return _data;
};
